import { render, staticRenderFns } from "./dishranking.vue?vue&type=template&id=2dc9771c&scoped=true&"
import script from "./dishranking.vue?vue&type=script&lang=js&"
export * from "./dishranking.vue?vue&type=script&lang=js&"
import style0 from "./dishranking.vue?vue&type=style&index=0&id=2dc9771c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc9771c",
  null
  
)

export default component.exports