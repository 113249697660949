<template>
  <div class="yingshou">
    <div class="right">

      <div class="databox">
        <!-- 报表类型 -->
        <div class="databox-left" style="background: #f4f4f4">
          <div class="
              ft-28
              flex flex-ac flex-jsb
              bg-fff
              pd-tp10 pd-rt20 pd-bt10 pd-lt30
            " style="height: 6rem">
            <span class="ft-bold">报表类型</span>
          </div>
          <div class="type-list">
            <div :class="[
              'type-list-body',
              type_id == item.type ? ['choosed', 'databox-type-check'] : '',
            ]" v-for="(item, index) in typeList" :key="index" @click="gettype(item, index)">
              {{ item.text }}
            </div>
          </div>
        </div>
        <div class="data-content-box">
          <div class="databox-right">
            <!-- 筛选条件 -->
            <div class="search-value">
              <div class="flex flex-ac search-item">
                <div class="flex flex-jsc dimension">
                  <div v-for="(item, index) in dimensionDate" :key="index" :class="[
                    'dimensiondate',
                    date_id == index ? 'dimensiondate-check' : '',
                  ]" @click="chooseType(item, index)">
                    <span> {{ item }}</span>
                  </div>
                </div>
                <input v-model="queryForm.start_time" class="ordernum-input search-date-input" readonly placeholder="选择时间"
                  @click="showCalendarStart = true" style="margin: 0 0 0 2rem" />
                <span class="ft-24">&nbsp;&nbsp;至&nbsp;&nbsp;</span>
                <input v-model="queryForm.end_time" class="ordernum-input search-date-input" placeholder="选择时间" readonly
                  @click="showCalendarEnd = true" />
              </div>
              <van-popup v-model="showCalendarStart" round position="bottom" class="popup-picker-custom">
                <van-datetime-picker
                  v-model="defaultDate[0]"
                  :min-date="minDate"
                  :max-date="maxDate"
                  type="datetime"
                  @confirm="onConfirmTime($event, 1)"
                  @cancel="showCalendarStart = false"
                  item-height="8.5rem"
                  :formatter="formatter"
                />
              </van-popup>
              <van-popup v-model="showCalendarEnd" round position="bottom" class="popup-picker-custom">
                <van-datetime-picker
                  v-model="defaultDate[1]"
                  :min-date="minDate"
                  :max-date="maxDate"
                  type="datetime"
                  @confirm="onConfirmTime($event, 2)"
                  @cancel="showCalendarEnd = false"
                  item-height="8.5rem"
                  :formatter="formatter"
                />
              </van-popup>
  
              <button class="search-btn mg-rt20" @click="handleFifler">筛选</button>
              <button class="search-btn" @click="resetTemp">重置</button>
            </div>
            <!-- 经营概况 -->
            <div v-if="type_id == 0 && isShowBus" class="business-profile">
              <businessProfile
                :businessExpressData="businessExpressData"
                :dishRankingData="dishRankingData"
                :businessTrendsData="businessTrendsData"
                :memberRankingData="memberRankingData"
                :queryForm="queryForm"
              ></businessProfile>
            </div>
            <!-- 账单汇总 -->
            <div v-if="type_id == 1">
              <div class="revenue-title flex flex-jsb">
                <div class="flex" style="align-items: center">
                  <div class="ft-bold" v-if="revenData.start_time != '' || revenData.end_time != ''">
                    时间：{{ revenData.start_time }} &nbsp;&nbsp;至&nbsp;&nbsp;{{
                        revenData.end_time
                    }}
                  </div>
                </div>
              </div>
  
              <table width="100%" style="border-collapse: collapse; margin-top: 2.5rem" border="0" cellspacing="0"
                cellpadding="0">
                <thead>
                  <tr bgcolor="#F2F2F2" class="thead-tr" style="height: 7.5rem;">
                    <td :class="[
                      'color-333',
                      'ft-22',
                      'text-center',
                      index == 0 ? 'tablewidth' : '',
                      'ft-bold',
                    ]" v-for="(item, index) in theadSummary" :key="index">
                      {{ item }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr :class="['color-333', 'ft-22', 'text-center', 'tbody-tr']" v-for="(item, index) in revenList"
                    :key="index">
                    <td class="tablewidth">{{ item.pay_time }}</td>
                    <td>{{ item.order_nums }}</td>
                    <td>{{ item.change_price }}</td>
                    <td>{{ item.discount_amount }}</td>
                    <td>{{ item.return_money }}</td>
                    <td>{{ item.total_price }}</td>
                  </tr>
                  <div class="mg-tp30 content" v-if="revenList.length === 0">
                    <div class="nullStyle">
                      <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
                    </div>
                  </div>
                </tbody>
              </table>
              <div class="flex mg-tp40" style="justify-content:flex-end;">
                <div class="pagination page-box-class" v-if="count > 0">
                  <van-pagination v-model="page" :total-items="count" :items-per-page="limit" mode="simple" force-ellipses
                    @change="getrevenueList" style="background-color: #f2f2f2"
                    :class="{'pagination-text-hidden': pageOptions.length > 0,}" />
                  <!-- <van-dropdown-menu v-if="pageOptions.length > 0" direction="up" class="page-box-dropdown" ref="pageDropdown" :overlay="false" active-color="#1989fa">
                    <van-dropdown-item v-model="choose_page" :options="pageOptions" @change="handleChangePage" />
                  </van-dropdown-menu> -->
                </div>
              </div>
  
              <table v-if="count > 0" width="100%" style="border-collapse: collapse;" border="0" cellspacing="0"
                cellpadding="0">
                <tbody style="overflow-y: scroll; position: relative;">
                  <tr :class="['color-333', 'ft-22', 'text-center', 'tbody-tr']">
                    <td class="tablewidth ft-bold">合计：</td>
                    <td class="ft-bold">{{ reveninfo.total_order_nums }}</td>
                    <td class="ft-bold">{{ reveninfo.total_change_price }}</td>
                    <td class="ft-bold">{{ reveninfo.total_discount_amount }}</td>
                    <td class="ft-bold">{{ reveninfo.total_return_money }}</td>
                    <td class="ft-bold">{{ reveninfo.total_total_price }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 订单明细 -->
            <div v-if="type_id == 2">
              <salesDetailsVue :isShowP="isShowP" :saleData="saleData" :queryForm="queryForm" 
                ref="salesDetailsVue" @filterSaleReport="filterSaleReport"></salesDetailsVue>
            </div>
            <!-- 商品销量 -->
            <div v-if="type_id == 3">
              <dishranking :isShowP="isShowP" :dishData="dishData" @foodsearch="foodsearch" :queryForm="queryForm"
                @foodnext="foodnext" @numsort="numsort" @selectTab="selectTab" :foodtype="food_type" ref="dishranking"></dishranking>
            </div>
            <!-- 客户分析 -->
            <memberprofile v-if="type_id == 4" :isShowP="isShowP" :queryForm="queryForm" :memberData="memberData"
              :memberTypeList="memberTypeList" @memberSearch="memberSearch" @memberreset="memberreset"
              @nextmember="nextmember" ref="memberprofile"></memberprofile>
            <!-- 订单收款 -->
            <div v-if="type_id == 5">
              <payment :isShowP="isShowP" :payReportdata="payReportdata" :queryForm="queryForm" @nextpay="nextpay">
              </payment>
            </div>
            <!-- 充值明细 -->
            <div v-if="type_id == 6">
              <rechargeDetailsVue :isShowP="isShowP" :rechargeData="rechargeData" :queryForm="queryForm" @filterRecharge="filterRecharge"></rechargeDetailsVue>
            </div>
            <!-- 库存变动记录 -->
            <!-- <div v-if="type_id == 7">
              <stockDetails :isShowP="isShowP" :stockLogdata="stockLogdata" :queryForm="queryForm" @filterStockLog="filterStockLog" :page="page" :limit="limit"></stockDetails>
            </div> -->
            <!-- 交接班记录 -->
            <div v-if="type_id == 8">
              <shiftRecords :isShowP="isShowP" :shiftData="shiftData" :queryForm="queryForm" @filterShift="filterShift"></shiftRecords>
            </div>
          </div>
        </div>
      </div>
    </div>

    <popupOfDialog
        v-if="showDialog"
        :type="dialogType"
        :title="dialogTitle"
        :message="dialogMsg"
        :data="{}"
        :showCancelBtn="false"
        :showConfirmBtn="false"
        @closePopDialog="closePopDialog"
    ></popupOfDialog>
  </div>
</template>

<script>
import salesDetailsVue from "./salesDetails.vue";
import dishranking from "./dishranking.vue";
import memberprofile from "./memberprofile.vue";
import payment from "./payment.vue";
import businessProfile from './businessProfile.vue'
import { datetimePickerFormatter, formatTime, formatTimeWitchLine, getBeforeDate } from '../../utils/util';
import popupOfDialog from "../component/popupOfDialog.vue";
import rechargeDetailsVue from './recharge-details.vue';
import stockDetails from "./stock-details.vue";
import shiftRecords from "./shift-records.vue";
export default {
  name: "revenue",
  components: {
    salesDetailsVue,
    dishranking,
    memberprofile,
    payment,
    businessProfile,
    popupOfDialog,
    rechargeDetailsVue,
    stockDetails,
    shiftRecords,
  },
  data() {
    return {
      queryForm: {
        start_time: "",
        end_time: "",
      },
      page: 1, // 当前页数
      limit: 6,
      count: 0,
      hasMore: false,
      showCalendarStart: false,
      minDate: new Date(),
      maxDate: new Date(),
      lableDown: {
        initial: "下拉加载更多",
        suspend: "松开加载更多",
        loading: "加载中",
        complete: "加载完成",
      },
      defaultDate: [],
      startDateTime: 0,
      endDateTime: 0,
      showCalendarEnd: false,
      dimensionDate: ["今天", "昨天", "7天", "30天"],
      date_id: 0,
      // typeList: ["经营概况", "订单汇总", "订单明细", "商品销量", "客户分析", "收款统计", "充值明细"],
      typeList: [
        { type: 0, text: '经营概况' },
        { type: 1, text: '订单汇总' },
        { type: 2, text: '订单明细' },
        { type: 6, text: '充值明细' },
        { type: 3, text: '商品销量' },
        { type: 4, text: '客户分析' },
        { type: 5, text: '订单收款' },
        // { type: 7, text: '库存变动记录' },
        { type: 8, text: '交接班记录' },
      ],
      type_id: 0,
      theadSummary: [
        "时间",
        "订单数量",
        "原价",
        "优惠金额",
        "退款",
        "实收",
      ],
      revenList: [],
      reveninfo: {},
      revenData: {},
      saleData: {},
      dishData: {},
      memberData: {},
      memberTypeList: [],
      payReportdata: {},
      isShowP: false,
      businessExpressData: {}, // 营业快报
      dishRankingData: {}, // 商品排行
      businessTrendsData: {}, // 营业趋势
      memberRankingData: {}, // 会员排行
      isShowBus: false,
      food_type: 1,
      formatter: datetimePickerFormatter,
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      dialogType: "",
      rechargeData: {},
      pageOptions: [],
      choose_page: 1,
      stockLogdata: {},
      shiftData: {},
    };
  },
  computed: {},
  methods: {
    filterShift(data) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let params = {
        page: data.page,
        limit: data.limit,
        s_time: this.queryForm.start_time,
        e_time: this.queryForm.end_time,
        is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        staff_name: data.search_content,
      }
      this.getShiftRecord(params);
    },
    getShiftRecord(f_params) {
      return new Promise((resolve, reject) => {
        let params = {}
        if (f_params) {
          params = f_params;
        } else {
          params = {
            page: this.page,
            limit: this.limit,
            s_time: this.queryForm.start_time,
            e_time: this.queryForm.end_time,
            is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
          }
        }
        this.$api.HandoverLog_getList(params).then(res => {
          this.$toast.clear();
          if (res.code == 1) {
            this.shiftData = res.data;
            this.shiftData.start_time = this.queryForm.start_time;
            this.shiftData.end_time = this.queryForm.end_time;
            resolve();
          } else {
            this.shiftData = {
              list: [],
              page: 1,
              limit: 6,
              count: 0,
            };
            this.count = 0;
            this.page = 1;
            this.limit = 6;
            this.dialogTitle = '提示';
            this.dialogType = 'businessData';
            this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
            this.showDialog = true;
            reject();
          }
        })
      })
    },
    filterStockLog(data) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let params = {
        page: data.page,
        limit: data.limit,
        time_start: this.queryForm.start_time,
        time_end: this.queryForm.end_time,
        is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        name: data.name,
        change_type: data.change_type,
      }
      this.page = data.page;
      this.limit = data.limit;
      this.getStockLogs(params);
    },
    getStockLogs(f_params) {
      return new Promise((resolve, reject) => {
        let params = {}
        if (f_params) {
          params = f_params;
        } else {
          params = {
            page: this.page,
            limit: this.limit,
            time_start: this.queryForm.start_time,
            time_end: this.queryForm.end_time,
            is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
          }
        }
        this.$api.getStockLog(params).then(res => {
          this.$toast.clear();
          if (res.code == 1) {
            if (!res.data || res.data.length == 0) {
              this.stockLogdata = {
                data: [],
                count: 0,
              };
              this.count = 0;
              this.page = 1;
              this.limit = 6;
            } else {
              this.stockLogdata = res.data;
            }
            resolve();
          } else {
            this.stockLogdata = {
              data: [],
              count: 0,
            };
            this.count = 0;
            this.page = 1;
            this.limit = 6;
            this.dialogTitle = '提示';
            this.dialogType = 'businessData';
            this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
            this.showDialog = true;
            reject();
          }
        })
      })
    },
    handleChangePage(e) {
      if (this.page != e) {
        this.page = e;
        this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0,
        });
        this.getrevenueList();
      }
    },
    filterRecharge(data) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let params = {
        page: data.page,
        limit: data.limit,
        start_time: this.queryForm.start_time,
        end_time: this.queryForm.end_time,
        is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        search_content: data.search_content,
      }
      this.getRechargeRecord(params);
    },
    getRechargeRecord(f_params) {
      return new Promise((resolve, reject) => {
        let params = {}
        if (f_params) {
          params = f_params;
        } else {
          params = {
            page: this.page,
            limit: this.limit,
            start_time: this.queryForm.start_time,
            end_time: this.queryForm.end_time,
            is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
          }
        }
        this.$api.Report_getRechargeDetails(params).then(res => {
          this.$toast.clear();
          if (res.code == 1) {
            this.rechargeData = res.data;
            resolve();
          } else {
            this.rechargeData = {
              list: [],
              total_info: {},
              page: 1,
              limit: 6,
              count: 0,
            };
            this.count = 0;
            this.page = 1;
            this.limit = 6;
            this.dialogTitle = '提示';
            this.dialogType = 'businessData';
            this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
            this.showDialog = true;
            reject();
          }
        })
      })
    },
    closePopDialog() {
      this.showDialog = false;
    },
    getType0Data() {
      Promise.all([this.businessExpress(), this.businessTrends(), this.getDishRanking(), this.memberRanking()]).then(result => {
        // console.log('resule---', result);
        this.$toast.clear();
        this.isShowBus = true;
      }).catch(e => {
        this.$toast.clear();
        this.isShowBus = false;
        // console.log('e', e);
        this.dialogTitle = '提示';
        this.dialogType = 'businessData';
        this.dialogMsg = e.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
        this.showDialog = true;
      })
    },
    // 商品排行
    getDishRanking() {
      return new Promise((resolve, reject) => {
        let data = {
          start_time: this.queryForm.start_time,
          end_time: this.queryForm.end_time,
          is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        };
        this.$api.Report_getProductRanking(data).then((res) => {
          if (res.code == 1) {
            this.dishRankingData = res.data;
            resolve(true);
          } else {
            this.dishRankingData = {
              top: [],
              bottom: [],
            }
            reject(res);
          }
        }).catch(() => {
          this.dishRankingData = {
            top: [],
            bottom: [],
          }
          reject(false);
        });
      })
    },
    // 营业趋势
    businessTrends() {
      return new Promise((resolve, reject) => {
        let data = {
          start_time: this.queryForm.start_time,
          end_time: this.queryForm.end_time,
          is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        };
        this.$api.Report_getBusinessTrends(data).then((res) => {
          if (res.code == 1) {
            this.businessTrendsData = res.data;
            resolve(true);
          } else {
            this.businessTrendsData = {
              day: [],
              list: []
            }
            reject(res);
          }
        }).catch(() => {
          this.businessTrendsData = {
            day: [],
            list: []
          }
          reject(false);
        });
      });
    },
    // 会员排行
    memberRanking() {
      return new Promise((resolve, reject) => {
        let data = {
          type: 0,
          start_time: this.queryForm.start_time,
          end_time: this.queryForm.end_time,
          is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        };
        this.$api.Report_getMemberRanking(data).then((res) => {
          if (res.code == 1) {
            this.memberRankingData = res.data;
            resolve(true);
          } else {
            this.memberRankingData = {
              order_top: [],
              recharge_top: [],
            }
            reject(res);
          }
        }).catch(() => {
          this.memberRankingData = {
            order_top: [],
            recharge_top: [],
          }
          reject(false);
        });
      })
    },
    // 营业快报
    businessExpress() {
      return new Promise((resolve, reject) => {
        let data = {
          start_time: this.queryForm.start_time,
          end_time: this.queryForm.end_time,
          is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        };
        this.$api.Report_getOperateSummary(data).then((res) => {
          if (res.code == 1) {
            this.businessExpressData = res.data;
            resolve(true);
          } else {
            this.businessExpressData = {
              member_num: 0,
              order_num: 0,
              trade_total: '0.00',
              avg_price: '0.00',
              total_price: '0.00',
              start_time: '',
              end_time: '',
            }
            reject(res);
          }
        }).catch(() => {
          this.businessExpressData = {
            member_num: 0,
            order_num: 0,
            trade_total: '0.00',
            avg_price: '0.00',
            total_price: '0.00',
            start_time: '',
            end_time: '',
          }
          reject(false);
        });
      })
    },
    async selectTab(data) {
      this.$toast.loading({
        message: '加载中...',
        duration: 3000
      })
      this.food_type = data.type;
      this.dishData = {};
      await this.dishRanking();
      this.isShowP = true;
    },
    onConfirmTime(event, type) {
      // console.log(event, type);
      if (type == 1) {
        if (
          this.endDateTime > 0 &&
          new Date(event).getTime() > this.endDateTime
        ) {
          this.$toast("开始日期不能大于结束日期");
          return;
        }
        this.startDateTime = new Date(event).getTime();
        this.queryForm.start_time = formatTime(event, 'yyyy-MM-dd hh:mm');
        this.showCalendarStart = false;
      } else {
        if (
          this.startDateTime > 0 &&
          new Date(event).getTime() < this.startDateTime
        ) {
          this.$toast("结束日期不能小于开始日期");
          return;
        }
        this.endDateTime = new Date(event).getTime();
        this.queryForm.end_time = formatTime(event, 'yyyy-MM-dd hh:mm');
        this.showCalendarEnd = false;
      }
      // if (this.queryForm.start_time != "" && this.queryForm.end_time != "") {
      //   this.handleFifler();
      // }
    },
    handleFifler() {
      if (this.queryForm.start_time == "" && this.queryForm.end_time != "") {
        this.$toast("请选择开始日期");
        return;
      }
      if (this.queryForm.start_time != "" && this.queryForm.end_time == "") {
        this.$toast("请选择结束日期");
        return;
      }
      this.page = 1;
      // this.getData();
      this.gettableType();
    },
    refreshReset() {
      this.page = 1;
      // this.getData();
      this.gettableType();
    },
    resetTemp() {
      this.queryForm = {
        start_time: "",
        end_time: "",
      };
      this.date_id = 0;
      this.getselectTime();
      this.handleFifler();
    },
    //下拉刷新
    addNew() {
      // console.log("下拉分页显示");
      return new Promise((resolve, reject) => {
        this.refreshReset();
        resolve();
      });
    },
    // 上拉加载
    addMore() {
      return new Promise((resolve, reject) => {
        if (this.hasMore == true) {
          this.$toast.loading({
            message: "加载中...",
            forbidClick: false,
          });
          this.page++;
          // this.getData();
        } else {
          this.$toast.loading({
            message: "没有更多啦",
            forbidClick: false,
          });
        }
        resolve();
      });
    },
    // 切换查询维度
    chooseType(item, index) {
      this.date_id = index;
      this.getselectTime();
      if (this.type_id == 2) {
        this.$refs.salesDetailsVue.order_num = '';
      }
      if (this.type_id == 3) {
        this.$refs.dishranking.dish_ranking = '';
      }
      if (this.type_id == 4) {
        this.$refs.memberprofile.member_typename = '';
        this.$refs.memberprofile.member_typeid = -1;
      }
      setTimeout((res) => {
        this.gettableType();
      }, 300);
    },
    // 切换报表类型
    gettype(item, index) {
      if (this.type_id != item.typw) {
        this.type_id = item.type;
        this.gettableType();
      }
    },
    // 营收列表
    getrevenueList() {
      this.pageOptions = [];
      this.choose_page = this.page;
      return new Promise((resolve, reject) => {
        let data = {
          page: this.page,
          limit: this.limit,
          start_time: this.queryForm.start_time,
          end_time: this.queryForm.end_time,
          // type: 0,
          is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        };
        this.$api.Report_getOrderSummary(data).then((res) => {
          this.$toast.clear();
          if (res.code == 1) {
            this.reveninfo = res.data.total_info;
            this.revenData = res.data;
            this.revenList = res.data.list;
            this.count = res.data.count;
            this.page = Number(res.data.page);
            this.limit = Number(res.data.limit);
            resolve();
          } else {
            this.reveninfo = {};
            this.revenList = [];
            this.count = 0;
            this.page = 1;
            this.limit = 6;
            this.dialogTitle = '提示';
            this.dialogType = 'businessData';
            this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
            this.showDialog = true;
            reject();
          }
        });
      });
    },
    //根据报表类型调接口
    async gettableType() {
      this.isShowP = false;
      // console.log("this.type_id", this.type_id);
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      switch (this.type_id) {
        case 0:
          this.getType0Data();
          break;
        case 1:
          await this.getrevenueList();
          break;
        case 2:
          await this.getsaleReportData();
          break;
        case 3:
          await this.dishRanking(1);
          break;
        case 4:
          await this.getmemberTypeList();
          await this.memberReport();
          break;
        case 5:
          await this.getpayReport();
          break;
        case 6:
          await this.getRechargeRecord();
          console.log('899898');
          break;
        case 7:
          await this.getStockLogs();
          break;
        case 8:
          await this.getShiftRecord();
          break;
      }
      this.isShowP = true;
      console.log('yyyyyyy');
    },
    filterSaleReport(data) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let params = {
        page: data.page,
        limit: data.limit,
        start_time: this.queryForm.start_time,
        end_time: this.queryForm.end_time,
        is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        order_num: data.order_num,
      }
      this.getsaleReportData(params);
    },
    // 销售明细列表
    getsaleReportData(f_params) {
      return new Promise((resolve, reject) => {
        let data = {};
        if (f_params) {
          data = f_params;
        } else {
          data = {
            page: 1,
            limit: 6,
            order_num: "",
            start_time: this.queryForm.start_time,
            end_time: this.queryForm.end_time,
            // type: 0,
            is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
          };
        }
        this.$api.Report_getOrderDetails(data).then((res) => {
          this.$toast.clear();
          if (res.code == 1) {
            this.saleData = res.data;
            resolve();
          } else {
            this.saleData = {
              list: [],
              total_info: {},
              page: 1,
              limit: 6,
              count: 0,
            };
            this.count = 0;
            this.page = 1;
            this.limit = 6;
            this.dialogTitle = '提示';
            this.dialogType = 'businessData';
            this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
            this.showDialog = true;
            reject();
          }
        });
      });
    },
    // 选择时间
    getselectTime() {
      switch (this.date_id) {
        case 0:
          this.queryForm.start_time = formatTimeWitchLine(new Date()) + ' 00:00';
          this.queryForm.end_time = formatTimeWitchLine(new Date()) + ' 23:59';
          this.defaultDate = [new Date(), new Date()];
          break;
        case 1:
          let beforeDate = getBeforeDate(1);
          this.queryForm.start_time = formatTimeWitchLine(beforeDate) + ' 00:00';
          this.queryForm.end_time = formatTimeWitchLine(beforeDate) + ' 23:59';
          this.defaultDate = [beforeDate, new Date()];
          break;
        case 2:
          let beforeDate_1 = getBeforeDate(6);
          this.queryForm.start_time = formatTimeWitchLine(beforeDate_1) + ' 00:00';
          this.queryForm.end_time = formatTimeWitchLine(new Date()) + ' 23:59';
          this.defaultDate = [beforeDate_1, new Date()];
          break;
        case 3:
          let beforeDate_2 = getBeforeDate(29);
          this.queryForm.start_time = formatTimeWitchLine(beforeDate_2) + ' 00:00';
          this.queryForm.end_time = formatTimeWitchLine(new Date()) + ' 23:59';
          this.defaultDate = [beforeDate_2, new Date()];
          break;
        default:
          break;
      }
    },
    // 查询商品排行
    dishRanking() {
      return new Promise((resolve, reject) => {
        let data = {
          page: 1,
          limit: 6,
          food_name: "",
          start_time: this.queryForm.start_time,
          end_time: this.queryForm.end_time,
          type: 0,
          is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        };
        this.$api.Report_getProductSales(data).then((res) => {
          this.$toast.clear();
          if (res.code == 1) {
            this.dishData = res.data;
            resolve();
          } else {
            this.dishData = {
              list: [],
              food_summary: {},
              page: 1,
              limit: 6,
              count: 0,
            };
            this.count = 0;
            this.page = 1;
            this.limit = 6;
            this.dialogTitle = '提示';
            this.dialogType = 'businessData';
            this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
            this.showDialog = true;
            reject();
          }
        });
      });
    },
    // 搜索商品
    foodsearch(data) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      data.start_time = this.queryForm.start_time;
      data.end_time = this.queryForm.end_time;
      data.is_opening_hours = 0; // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
      this.$api.Report_getProductSales(data).then((res) => {
        this.$toast.clear();
        if (res.code == 1) {
          this.dishData = res.data;
        } else {
          this.dishData = {
            list: [],
            food_summary: {},
            page: 1,
            limit: 6,
            count: 0,
          };
          this.count = 0;
          this.page = 1;
          this.limit = 6;
          this.dialogTitle = '提示';
          this.dialogType = 'businessData';
          this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
          this.showDialog = true;
        }
      });
    },
    // 商品列表下一页
    foodnext(data) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      data.start_time = this.queryForm.start_time;
      data.end_time = this.queryForm.end_time;
      data.is_opening_hours = 0; // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
      this.$api.Report_getProductSales(data).then((res) => {
        this.$toast.clear();
        if (res.code == 1) {
          this.dishData = res.data;
        } else {
          this.dishData = {
            list: [],
            food_summary: {},
            page: 1,
            limit: 6,
            count: 0,
          };
          this.count = 0;
          this.page = 1;
          this.limit = 6;
          this.dialogTitle = '提示';
          this.dialogType = 'businessData';
          this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
        }
      });
    },
    // 商品列表排序
    numsort(data) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      data.start_time = this.queryForm.start_time;
      data.end_time = this.queryForm.end_time;
      data.is_opening_hours = 0; // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
      this.$api.Report_getProductSales(data).then((res) => {
        this.$toast.clear();
        if (res.code == 1) {
          this.dishData = res.data;
        } else {
          this.dishData = {
            list: [],
            food_summary: {},
            page: 1,
            limit: 6,
            count: 0,
          };
          this.count = 0;
          this.page = 1;
          this.limit = 6;
          this.dialogTitle = '提示';
          this.dialogType = 'businessData';
          this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
        }
      });
    },
    // 会员概况列表
    memberReport() {
      return new Promise((resolve, reject) => {
        let data = {
          page: 1,
          limit: 6,
          start_time: this.queryForm.start_time,
          end_time: this.queryForm.end_time,
          type: 0,
          member_action: -1,
          is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        };
        this.$api.Report_getMemberPayCount(data).then((res) => {
          this.$toast.clear();
          if (res.code == 1) {
            this.memberData = res.data;
            resolve();
          } else {
            this.memberData = {
              list: [],
              total_info: {},
              page: 1,
              limit: 6,
              count: 0,
            };
            this.count = 0;
            this.page = 1;
            this.limit = 6;
            this.dialogTitle = '提示';
            this.dialogType = 'businessData';
            this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
            this.showDialog = true;
            reject();
          }
        });
      });
    },
    // 会员类型列表
    getmemberTypeList() {
      this.$api.getMemberTypeList().then((res) => {
        if (res.code == 1) {
          this.memberTypeList = res.data.data || [];
          this.memberTypeList.unshift({
            id: 0,
            type_name: '非会员'
          })
        }
      });
    },
    // 会员搜索
    memberSearch(data) {
      return new Promise((resolve, reject) => {
        this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0,
        });
        data.start_time = this.queryForm.start_time;
        data.end_time = this.queryForm.end_time;
        data.is_opening_hours = 0; // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        this.$api.Report_getMemberPayCount(data).then((res) => {
          this.$toast.clear();
          if (res.code == 1) {
            this.memberData = res.data;
            resolve();
          } else {
            this.memberData = {
              list: [],
              total_info: {},
              page: 1,
              limit: 6,
              count: 0,
            };
            this.count = 0;
            this.page = 1;
            this.limit = 6;
            this.dialogTitle = '提示';
            this.dialogType = 'businessData';
            this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
            this.showDialog = true;
            reject();
          }
        });
      });
    },
    // 会员重置
    memberreset() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.memberReport();
    },
    // 会员分页
    nextmember(data) {
      return new Promise((resolve, reject) => {
        this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
          duration: 0,
        });
        data.start_time = this.queryForm.start_time;
        data.end_time = this.queryForm.end_time;
        data.is_opening_hours = 0; // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        this.$api.Report_getMemberPayCount(data).then((res) => {
          this.$toast.clear();
          if (res.code == 1) {
            this.memberData = res.data;
            resolve();
          } else {
            this.memberData = {
              list: [],
              total_info: {},
              page: 1,
              limit: 6,
              count: 0,
            };
            this.count = 0;
            this.page = 1;
            this.limit = 6;
            this.dialogTitle = '提示';
            this.dialogType = 'businessData';
            this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
            this.showDialog = true;
            reject();
          }
        });
      });
    },
    // 支付分析
    getpayReport() {
      return new Promise((resolve, reject) => {
        let data = {
          page: 1,
          limit: 6,
          type: 0,
          start_time: this.queryForm.start_time,
          end_time: this.queryForm.end_time,
          is_opening_hours: 0, // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
        };
        this.$api.Report_getOrderCollectionCount(data).then((res) => {
          this.$toast.clear();
          if (res.code == 1) {
            this.payReportdata = res.data;
            resolve();
          } else {
            this.payReportdata = {
              list: [],
              total_info: {},
              page: 1,
              limit: 6,
              count: 0,
            };
            this.count = 0;
            this.page = 1;
            this.limit = 6;
            this.dialogTitle = '提示';
            this.dialogType = 'businessData';
            this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
            this.showDialog = true;
            reject();
          }
        });
      });
    },
    // 支付分页
    nextpay(data) {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      data.start_time = this.queryForm.start_time;
      data.end_time = this.queryForm.end_time;
      data.is_opening_hours = 0; // 是否为营业时间进行统计，1：按照营业时间统计，0：按照自然日统计
      this.$api.Report_getOrderCollectionCount(data).then((res) => {
        this.$toast.clear();
        if (res.code == 1) {
          this.payReportdata = res.data;
        } else {
          this.payReportdata = {
            list: [],
            total_info: {},
          };
          this.count = 0;
          this.page = 1;
          this.limit = 6;
          this.dialogTitle = '提示';
          this.dialogType = 'businessData';
          this.dialogMsg = res.msg || '高峰期（18:00-23:59）只可查询当天数据，更多数据请在其他时段查询。';
          this.showDialog = true;
        }
      });
    },
  },
  created() {
    let newD = new Date().setMonth(new Date().getMonth() - 2)
    newD = new Date(newD).setHours(0);
    newD = new Date(newD).setMinutes(0);
    this.minDate = new Date(newD);
    this.maxDate = new Date();
    // return
    this.getselectTime();
    setTimeout((res) => {
      this.gettableType(0);
    }, 500);
  },
  mounted() { },
};
</script>

<style lang="less" scoped>
.page-box-class {
  position: relative;
  /deep/.van-pagination {
    font-size: 1.9rem;
  }
  .pagination-text-hidden {
    /deep/.van-pagination__page-desc {
      color: #f2f2f2 !important;
    }
  }
  .page-box-dropdown {
    position: absolute;
    top: 0;
    height: 40px;
    background: #f2f2f2;
    width: 31.5%;
    left: 34%;
    color: #646566;
    /deep/.van-dropdown-item {
      width: 3rem;
      left: -0.1rem;
      height: 40vh;
      top: auto;
      position: absolute !important;
      bottom: 40px !important;
    }
    /deep/.van-dropdown-item__content {
      box-shadow: 0 0 0.07rem 0.07rem rgba(229,229,229,0.5);
      max-height: 100%;
      margin: 0 0.1rem;
      width: calc(100% - 0.2rem);
    }
    /deep/.van-dropdown-menu__bar {
      height: 100%;
      background: #f2f2f2;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title {
      color: #646566;
      font-size: 0.26rem;
      padding: 0;
    }
    /deep/.van-dropdown-menu__title--down::after,
    /deep/.van-dropdown-menu__title--active::after {
      display: none;
    }
    /deep/.van-cell {
      font-size: 0.34rem;
      padding: 0.25rem 0.25rem;
    }
    /deep/.van-cell__value {
      flex: none;
    }
  }
}
.business-profile {
  height: calc(100% - 9.5rem);
  overflow-y: scroll;
}
.yingshou {
  height: 100%;
  width: 100%;

  .right {
    height: 100%;
    width: 100%;

    .search-value {
      display: flex;
      flex-wrap: wrap;
      // background: #fff;
      padding: 0 3rem 0 0;
      margin: 0 0 2rem 0;
      align-items: center;

      .search-item {
        margin: 0 3rem 0 0;

        .timestyle {
          font-weight: 400;
          font-size: 2.4rem;
          color: #666666;
          padding-right: 2rem;
        }

        .dimension {
          border-radius: 0.8rem;
          font-weight: 400;
          font-size: 2.2rem;
          color: #666666;

          .dimensiondate {
            width: 7.8rem;
            height: 4.8rem;
            background: #ffffff;
            border: 0.1rem solid #dedede;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -0.2rem;
          }

          .dimensiondate:first-child {
            border-radius: 0.8rem 0 0 0.8rem;
          }
          .dimensiondate:last-child {
            border-radius: 0 0.8rem 0.8rem 0;
          }

          .dimensiondate-check {
            background: #1588F5;
            border-color: #1588F5;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -0.2rem;
            color: #fff;
          }
        }
      }

      .ordernum-input {
        border: 0.1rem #DEDEDE solid;
        width: 21.5rem;
        height: 4.8rem;
        border-radius: 0.8rem;
        font-size: 2.2rem;
        padding-left: 1.5rem;
      }

      .search-btn {
        background: #1588F5;
        font-size: 2.4rem;
        color: #fff;
        height: 4.8rem;
        padding: 0 2.5rem;
        border-radius: 0.8rem;
      }

      .search-date-input {
        width: 21.5rem;
        font-size: 2.4rem;
        // text-align: center;
      }
    }

    .databox {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: 100%;

      .databox-left,
      .databox-right {
        // background: #fff;
        // height: calc(100vh - 1.41rem);

        .databox-title {
          font-size: 2.8rem;
          font-weight: bold;
          margin-bottom: 2.5rem;
        }

        .revenue-title {
          font-size: 2.6rem;
          color: #333333;
          margin-top: 4rem;
        }

        .tablewidth {
          width: 25rem;
          word-break: break-word;
        }

        .otherwidth {
          width: 28rem;
        }

        .content {
          height: calc(100% - 15rem);
          margin: 2rem 0 0 0;
          flex-wrap: nowrap;
        }
      }

      .data-content-box {
        width: calc(100% - 33rem);
      }

      .databox-left {
        width: 33rem;
        font-size: 2.4rem;
        color: #000000;
        background: #fff;

        .databox-type {
          height: 11.7rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          padding: 2rem 3rem 2rem 4rem;
        }

        .databox-type-check {
          background: #e7f3ff;
          font-weight: 700;
        }
      }

      .databox-right {
        // width: calc(100% - 33rem);
        margin-left: 1.5rem;
        padding-left: 3.2rem;
        padding-right: 3.2rem;
        background: #fff;
        padding-top: 2rem;
        height: 100%;

        .list-body {
          .list-head {
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            background-color: #f2f2f2;
            color: #333;
            font-size: 2.8rem;
            height: 7rem;
            line-height: 7rem;
            border-radius: 0.8rem;

            div {
              flex: 1;
              text-align: center;
            }

            p {
              width: 3rem;
              text-align: center;
            }
          }

          .scrollY {
            height: calc(100vh - 4.5rem);
            overflow-y: scroll;

            .list-worker {
              display: flex;
              justify-content: space-between;
              font-weight: bold;
              font-size: 0.4rem;
              height: 1.3rem;
              line-height: 1.3rem;
              border-bottom: 0.02rem rgba(0, 0, 0, 0.06) solid;

              div {
                flex: 1;
                text-align: center;
              }

              .revenue-top-icon {
                width: 0.7rem;
                height: 0.6rem;
              }
            }
          }

          .odd {
            background: #fef7ef;
          }
        }
      }
    }
  }
}

.nullStyle {
  padding-top: 2rem;
  text-align: center;

  img {
    margin: 0 auto;
    width: 2.53rem;
    height: 1.77rem;
  }
}

table thead,
tbody tr,
tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;

  td {
    word-break: break-all;
  }
}

table {
  background: white;
  position: relative;
  display: table;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  ::-webkit-scrollbar {
    display: block;
    width: 0.6rem;
    height: 0.5rem;
    background-color: #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 0.5rem;
    min-width: 0.4rem;
    border-radius: 0.8rem;
    background-color: rgb(217, 217, 217);
  }

  thead {
    width: calc(100% - 0.6rem);
  }

  thead td {
    border: 0.15rem rgba(0, 0, 0, 0.06) solid;
  }

  tbody {
    overflow-y: scroll;
    display: block;
    height: calc(85vh - 38rem);
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }

  .tbody-tr {
    overflow-y: scroll;
    height: 7.5rem;
    width: 100%;

    &.odd {
      background: #fef7ef;
    }

    td {
      border: 0.15rem rgba(0, 0, 0, 0.06) solid;
    }
  }
}

.pagination {
  width: 30%;
}

/deep/.van-pagination__item--active {
  background-color: #1588F5;
}

.choosed {
  position: relative;
}

// .choosed::before {
//   content: "";
//   position: absolute;
//   top: 2%;
//   left: 0;
//   width: 0.13rem;
//   height: 96%;
//   background: #1588F5;
// }
.type-list {
  width: 100%;
  height: calc(100% - 6rem);
  background-color: #fff;
  font-size: 2.4rem;

  .type-list-body {
    display: flex;
    border: 0.15rem #f5f5f5 solid;
    padding: 1rem 2rem 1rem 4rem;
    height: 6rem;
    align-items: center;
    line-height: 1.6;
    flex-wrap: wrap;
    font-size: 2.3rem;
    position: relative;
  }

  .type-list-body-check {
    background: #e7f3ff;
    font-weight: 700;
  }
}
.total{
  height: 1.41rem;
  display: flex;
  width: 2.902rem;
  justify-content: center;
  align-items: center;
  border: 0.02rem rgba(0, 0, 0, 0.06) solid;
  word-break: break-all;
  text-align: center;
}

.border-rt-none {
  border-right: none !important;
}
</style>
