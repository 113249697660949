var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"revenue-title flex flex-jsb"},[_c('div',{staticClass:"flex",staticStyle:{"align-items":"center"}},[(
          (_vm.dishData.start_time != '' || _vm.dishData.end_time != '') && _vm.isShowP
        )?_c('div',{staticClass:"ft-bold"},[_vm._v(" 时间："+_vm._s(_vm.dishData.start_time)+"   至  "+_vm._s(_vm.dishData.end_time)+" ")]):_vm._e()])]),_c('div',{staticClass:"flex flex-ac mg-tp25"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dish_ranking),expression:"dish_ranking"}],staticClass:"search",attrs:{"type":"text","placeholder":"请输入商品名称"},domProps:{"value":(_vm.dish_ranking)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.dish_ranking=$event.target.value}}}),_c('button',{staticClass:"search-button",on:{"click":_vm.search}},[_vm._v("搜 索")])]),_c('table',{staticClass:"mg-tp25",staticStyle:{"border-collapse":"collapse"},attrs:{"width":"100%","border":"0","cellspacing":"0","cellpadding":"0"}},[_c('thead',[_c('tr',{staticClass:"thead-tr ft-22",staticStyle:{"height":"7.5rem"},attrs:{"bgcolor":"#F2F2F2"}},[_c('td',{class:['color-333', 'ft-22', 'text-center', 'foodname', 'ft-bold'],staticStyle:{"padding-left":"1rem"}},[_vm._v(" 商品名称 ")]),_c('td',{class:['color-333', 'ft-22', 'text-center', 'ft-bold']},[_vm._v("商品分类")]),_c('td',{class:['color-333', 'ft-22', 'text-center', 'ft-bold']},[_vm._v("销售均价")]),_c('td',{class:['color-333', 'ft-22', 'text-center', 'ft-bold']},[_vm._v(" 销售数量 "),_c('span',{staticClass:"caret-wrapper"},[_c('i',{class:[
                'sort-caret',
                'ascending',
                _vm.numascend ? 'numascending' : '' ],on:{"click":function($event){$event.stopPropagation();return _vm.Numberascending.apply(null, arguments)}}}),_c('i',{class:[
                'sort-caret',
                'descending',
                _vm.numdescend ? 'numdescending' : '' ],on:{"click":function($event){$event.stopPropagation();return _vm.Numberdescending.apply(null, arguments)}}})])]),_c('td',{class:['color-333', 'ft-22', 'text-center', 'ft-bold']},[_vm._v(" 销售金额 "),_c('span',{staticClass:"caret-wrapper"},[_c('i',{class:[
                'sort-caret',
                'ascending',
                _vm.moneyascend ? 'numascending' : '' ],on:{"click":function($event){$event.stopPropagation();return _vm.moneyascending.apply(null, arguments)}}}),_c('i',{class:[
                'sort-caret',
                'descending',
                _vm.moneydescend ? 'numdescending' : '' ],on:{"click":function($event){$event.stopPropagation();return _vm.moneydescending.apply(null, arguments)}}})])])])]),(_vm.isShowP)?_c('tbody',{staticStyle:{"overflow-y":"scroll"}},[_vm._l((_vm.tableList),function(item){return _c('tr',{key:item.id,class:['color-333', 'ft-22', 'text-center', 'tbody-tr']},[_c('td',{staticClass:"text-left",staticStyle:{"padding-left":"1rem"}},[_vm._v(_vm._s(item.pro_name))]),_c('td',[_vm._v(_vm._s(item.pro_cat))]),_c('td',[_vm._v(_vm._s(item.pro_avg_price)+"元/"+_vm._s(item.dw || ''))]),_c('td',[_vm._v(_vm._s(item.pro_nums))]),_c('td',[_vm._v(_vm._s(item.pro_price))])])}),(_vm.tableList.length == 0)?_c('div',{staticClass:"mg-tp30 content"},[_vm._m(0)]):_vm._e()],2):_vm._e()]),(_vm.isShowP)?_c('div',{staticClass:"flex mg-tp40",staticStyle:{"justify-content":"flex-end"}},[(_vm.tableList.length != 0)?_c('div',{staticClass:"pagination dr-page-box-class"},[_c('van-pagination',{class:{'pagination-text-hidden': _vm.pageOptions.length > 0,},staticStyle:{"background-color":"#f2f2f2"},attrs:{"total-items":_vm.pageform.count,"items-per-page":_vm.pageform.limit,"mode":"simple","force-ellipses":""},on:{"change":_vm.nextpage},model:{value:(_vm.pageform.page),callback:function ($$v) {_vm.$set(_vm.pageform, "page", $$v)},expression:"pageform.page"}})],1):_vm._e()]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nullStyle"},[_c('p',{staticClass:"text-center ft-24 color-999 mg-tp30"},[_vm._v("暂无数据~")])])}]

export { render, staticRenderFns }