<template>
  <!-- 经营概况 -->
  <div>
    <div class="revenue-title flex flex-jsb">
      <div class="flex" style="align-items: center">
        <div class="ft-bold" v-if="businessExpressData.start_time != '' || businessExpressData.end_time != ''">
          时间：{{ businessExpressData.start_time }} &nbsp;&nbsp;至&nbsp;&nbsp;{{ businessExpressData.end_time }}
        </div>
      </div>
    </div>
    
    <div class="collapse">
      <div class="collapse-title flex">营业快报</div>
    </div>
    <div class="report flex flex-jsb">
      <div
        v-for="(item, index) in titleList2"
        :key="index"
        class="flex report-title flex-ac flex-jc"
      >
        <div class="flex flex-column flex-ac report-title-count">
          <img
            class="img"
            :src="item.img"
            alt=""
            :class="[index == 2 ? 'img1' : '', { 'img0': index == 0 }]"
          />
          <span class="span">{{ item.name }}</span>
          <span class="span spanNum" v-if="index == 0 || index == 1 || index == 5 || index == 6"
            ><span style="font-size: 2rem; font-weight: 400;">￥</span>{{ item.num || '0.00' }}</span
          >
          <span class="span spanNum" v-else>{{ item.num || '0' }}</span>
        </div>
      </div>
    </div>
    <!-- 营业趋势 -->
    <div class="collapse">
      <div class="collapse-title flex">营业趋势</div>
      <div class="collapse-content">
        <div
          id="main"
          style="width: 100%; height: 50vh; background: #fff"
          v-if="isShowP"
        ></div>
      </div>
    </div>
    <!-- 商品排行 -->
    <div class="collapse">
      <div class="collapse-title flex">商品排行</div>
      <div class="collapse-content">
        <div class="flex flex-jsb" style="padding: 2rem">
          <div class="collapse-table">
            <div class="collapse-table-title">畅销榜</div>
            <table
              width="100%"
              style="border-collapse: collapse; margin-top: 2rem"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <thead>
                <tr
                  bgcolor="#1588F5"
                  class="thead-tr ft-24"
                  style="height: 7rem; font-weight: 600"
                >
                  <td class="theaderfont">排行</td>
                  <td class="theaderfont">商品名称</td>
                  <td class="theaderfont">销量</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in activeList"
                  :key="item.id"
                  :class="['color-333', 'ft-24', 'text-center', 'tbody-tr']"
                >
                  <td v-if="index <= 2" class="tabletd">
                    <img
                      v-if="index == 0"
                      src="@/static/images/revenue-top1.png"
                      class="revenue-top-icon"
                    />
                    <img
                      v-if="index == 1"
                      src="@/static/images/revenue-top2.png"
                      class="revenue-top-icon"
                    />
                    <img
                      v-if="index == 2"
                      src="@/static/images/revenue-top3.png"
                      class="revenue-top-icon"
                    />
                  </td>
                  <td v-if="index > 2" class="tabletd">{{ index + 1 }}</td>
                  <td class="tabletd">{{ item.name }}</td>
                  <td class="tabletd">{{ item.ordercount }}</td>
                </tr>
                <div class="mg-tp30 mg-bt30 content" v-if="activeList.length == 0">
                  <div class="nullStyle">
                    <p class="text-center ft-24 color-999 mg-tp30">
                      暂无数据~
                    </p>
                  </div>
                </div>
              </tbody>
            </table>
          </div>
          <div class="collapse-table">
            <div class="collapse-table-title2 collapse-table-title3">
              滞销榜
            </div>
            <table
              width="100%"
              style="border-collapse: collapse; margin-top: 2rem"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <thead>
                <tr
                  bgcolor="#333333"
                  class="thead-tr ft-24"
                  style="height: 7rem; font-weight: 600"
                >
                  <td class="theaderfont">排行</td>
                  <td class="theaderfont">商品名称</td>
                  <td class="theaderfont">销量</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in unsalableList"
                  :key="item.id"
                  :class="['color-333', 'ft-24', 'text-center', 'tbody-tr']"
                >
                  <td class="tabletd">{{ index + 1 }}</td>
                  <td class="tabletd">{{ item.name }}</td>
                  <td class="tabletd">{{ item.ordercount }}</td>
                </tr>
                <div class="mg-tp30 mg-bt30 content" v-if="activeList.length == 0">
                  <div class="nullStyle">
                    <p class="text-center ft-24 color-999 mg-tp30">
                      暂无数据~
                    </p>
                  </div>
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- 会员排行 -->
    <div class="collapse">
      <div class="collapse-title flex">会员排行</div>
      <div class="collapse-content">
        <div class="flex flex-jsb" style="padding: 2rem">
          <div class="collapse-table">
            <div class="collapse-table-title">消费榜</div>
            <table
              width="100%"
              style="border-collapse: collapse; margin-top: 2rem"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <thead>
                <tr
                  bgcolor="#1588F5"
                  class="thead-tr ft-24"
                  style="height: 7rem; font-weight: 600"
                >
                  <td class="theaderfont">排行</td>
                  <td class="theaderfont">会员名称</td>
                  <td class="theaderfont">消费金额</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in order_topList"
                  :key="item.pay_member_id"
                  :class="['color-333', 'ft-24', 'text-center', 'tbody-tr']"
                >
                  <td v-if="index <= 2" class="tabletd">
                    <img
                      v-if="index == 0"
                      src="@/static/images/revenue-top1.png"
                      class="revenue-top-icon"
                    />
                    <img
                      v-if="index == 1"
                      src="@/static/images/revenue-top2.png"
                      class="revenue-top-icon"
                    />
                    <img
                      v-if="index == 2"
                      src="@/static/images/revenue-top3.png"
                      class="revenue-top-icon"
                    />
                  </td>
                  <td v-if="index > 2" class="tabletd">{{ index + 1 }}</td>
                  <td class="tabletd">{{ item.name }}</td>
                  <td class="tabletd">{{ item.order_amount }}</td>
                </tr>
                <div
                  class="mg-tp30 mg-bt30 content"
                  v-if="order_topList.length == 0"
                >
                  <div class="nullStyle">
                    <p class="text-center ft-24 color-999 mg-tp30">
                      暂无数据~
                    </p>
                  </div>
                </div>
              </tbody>
            </table>
          </div>
          <div class="collapse-table">
            <div class="collapse-table-title2">充值榜</div>
            <table
              width="100%"
              style="border-collapse: collapse; margin-top: 2rem"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <thead>
                <tr
                  bgcolor="#3DB956"
                  class="thead-tr ft-24"
                  style="height: 7rem; font-weight: 600"
                >
                  <td class="theaderfont">排行</td>
                  <td class="theaderfont">会员名称</td>
                  <td class="theaderfont">充值金额</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in recharge_topList"
                  :key="item.pay_member_id"
                  :class="['color-333', 'ft-24', 'text-center', 'tbody-tr']"
                >
                  <td v-if="index <= 2" class="tabletd">
                    <img
                      v-if="index == 0"
                      src="@/static/images/revenue-top1.png"
                      class="revenue-top-icon"
                    />
                    <img
                      v-if="index == 1"
                      src="@/static/images/revenue-top2.png"
                      class="revenue-top-icon"
                    />
                    <img
                      v-if="index == 2"
                      src="@/static/images/revenue-top3.png"
                      class="revenue-top-icon"
                    />
                  </td>
                  <td v-if="index > 2" class="tabletd">{{ index + 1 }}</td>
                  <td class="tabletd">{{ item.name }}</td>
                  <td class="tabletd">{{ item.recharge_amount }}</td>
                </tr>
                <div
                  class="mg-tp30 mg-bt30 content"
                  v-if="recharge_topList.length == 0"
                >
                  <div class="nullStyle">
                    <p class="text-center ft-24 color-999 mg-tp30">
                      暂无数据~
                    </p>
                  </div>
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "businessProfile",
  data() {
    return {
      titleList2: [
        {
          name: "会员储值",
          num: "0",
          img: require("@/static/images/discount_num.png"),
        },
        {
          name: "销售额",
          num: "0",
          img: require("@/static/images/business_num.png"),
        },
        {
          name: "毛利额",
          num: "0",
          img: require("@/static/images/business_num.png"),
        },
        {
          name: "订单数量",
          num: "0",
          img: require("@/static/images/order_lists.png"),
        },
        {
          name: "新增会员",
          num: "0",
          img: require("@/static/images/add_menber.png"),
        },
        {
          name: "单均价",
          num: "0",
          img: require("@/static/images/kedanjia.png"),
        },
      ],
      time: [],
      original_List: [],
      actual_List: [],
      activeList: [],
      unsalableList: [],
      order_topList: [],
      recharge_topList: [],
      isShowP: false,
    };
  },
  props: {
    businessExpressData: {
      type: Object,
    }, // 营业快报
    dishRankingData: {
      type: Object,
    }, // 商品排行
    businessTrendsData: {
      type: Object,
    }, // 营业趋势
    memberRankingData: {
      type: Object,
    }, // 会员排行
    queryForm: {
      type: Object,
    },
  },
  methods: {
    // 营业快报
    setData1() {
      this.titleList2[0].num = this.businessExpressData.recharge_total || '0.00';
      this.titleList2[1].num = this.businessExpressData.total_price || '0.00';
      this.titleList2[2].num = this.businessExpressData.cost_price_total || 0;
      this.titleList2[3].num = this.businessExpressData.order_num || 0;
      this.titleList2[4].num = this.businessExpressData.member_num || 0;
      this.titleList2[5].num = this.businessExpressData.avg_price || '0';
    },
    // 营业趋势
    setData2() {
      // console.log('this.businessTrendsData', this.businessTrendsData);
      this.time = this.businessTrendsData.day || [];
      let a = [];
      let b = [];
      if (this.businessTrendsData.list) {
        this.businessTrendsData.list.forEach((item) => {
          // a.push(item.original_price);
          // b.push(item.actual_price);
          a.push(item.discount || 0);
          b.push(item.recharge_amount || 0);
        });
      }
      this.original_List = a;
      this.actual_List = b;
      this.isShowP = true;
      this.$nextTick(function () {
        this.drawLine("main");
      });
    },
    // 商品排行
    setData3() {
      this.activeList = this.dishRankingData.top || []; //前五
      this.unsalableList = this.dishRankingData.bottom || []; //后五
    },
    // 会员排行
    setData4() {
      this.order_topList = this.memberRankingData.order_top || [];
      this.recharge_topList = this.memberRankingData.recharge_top || [];
    },
    // 图表
    drawLine(id) {
      this.charts = echarts.init(document.getElementById(id));
      let option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["销售额", "会员储值"],
          fontSize: 24,
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.time,
          axisLabel: {
            fontSize: 16,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            fontSize: 18,
          },
        },
        series: [
          {
            name: "销售额",
            type: "line",
            // stack: "Total",
            data: this.original_List,
            lineStyle: {
              color: "#3DB956",
            },
          },
          {
            name: "会员储值",
            type: "line",
            // stack: "Total",
            data: this.actual_List,
            lineStyle: {
              color: "#1588F5",
            },
          },
        ],
        color: ["#3DB956", "#1588F5"],
      }
      this.charts.setOption(option);
      this.charts.dispatchAction({
        type: 'showTip',
        seriesIndex: 0, // 针对series下第几个数据
        dataIndex: option.series[0].data.length - 1 // 第几个数据
      })
    },
  },
  watch: {
    businessExpressData(newV, oldV) {
      this.businessExpressData = newV;
      this.setData1();
    },
    businessTrendsData(newV, oldV) {
      this.isShowP = false;
      this.businessTrendsData = newV;
      this.setData2();
    },
    dishRankingData(newV, oldV) {
      this.dishRankingData = newV;
      this.setData3();
    },
    memberRankingData(newV, oldV) {
      this.memberRankingData = newV;
      this.setData4();
    },
  },
  mounted() {
    this.setData1();
    this.setData2();
    this.setData3();
    this.setData4();
  },
};
</script>
<style lang="less" scoped>
.collapse {
  background: #fff;
  margin-top: 2.5rem;

  .collapse-title {
    font-size: 2.6rem;
    padding: 2rem;
  }

  .collapse-content {
    padding-bottom: 2.5rem;

    .collapse-table-title {
      font-weight: 500;
      font-size: 2.6rem;
      color: #1588F5;
      text-align: center;
      padding-top: 2.5rem;
    }

    .collapse-table-title2 {
      font-weight: 500;
      font-size: 2.6rem;
      color: #3DB956;
      text-align: center;
      padding-top: 2.5rem;
    }

    .collapse-table-title3 {
      color: #333333 !important;
    }

    .collapse-table {
      width: 46%;
      padding: 0 2rem;
      background: #ffffff;
      box-shadow: 0 0 0.35rem 0.35rem rgba(229,229,229,0.5);
      border-radius: 0.8rem;
    }

    .theaderfont {
      text-align: center;
      color: #fff;
    }

    .tabletd {
      height: 8rem;
      border-bottom: 0.2rem rgba(0, 0, 0, 0.06) solid;
    }

    .revenue-top-icon {
      width: 4.3rem;
      height: 3.3rem;
    }
  }
}
.report {
  background: #fff;
  padding: 0 3rem 3rem 3rem;

  .report-title {
    width: 15%;
    height: 23rem;
    // border: 1px solid rgba(153, 153, 153, 1);
    flex-direction: column;
    background: #f6f6f8;
    border-radius: 0.8rem;

    .report-title-count {
      .img {
        height: 4.3rem;
        width: 4rem;
      }

      .img1 {
        width: 4rem;
      }

      .img0 {
        width: 5.4rem;
      }

      .report-title-span {
        display: flex;
        flex-flow: column;
        flex-wrap: wrap;
        margin: auto;
      }

      .span {
        text-align: center;
        margin-top: 2.5rem;
        font-size: 2.2rem;
        color: #666666;
      }

      .spanNum {
        margin-top: 3rem;
        font-size: 2.4rem;
        color: #000000;
        font-weight: 600;
      }
    }
  }
}
.revenue-title {
  font-size: 2.6rem;
  color: #333333;
  margin-top: 2rem;
}

.tabletd {
  width: 2.4rem;
  border: 0.25rem solid #fff;
}

table thead,
tbody tr,
tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table {
  background: white;
  position: relative;
  display: table;
  // height: 100%;
  height: fit-content;
  // overflow-y: scroll;
  width: 100%;

  tbody {
    // overflow-y: scroll;
    display: block;
    // height: calc(85vh - 6rem);
    width: 100%;
  }

  .tbody-tr {
    // overflow-y: scroll;
    height: 1rem;
    width: 100%;
    border-bottom: 0.02rem rgba(0, 0, 0, 0.06) solid;

    &.odd {
      background: #fef7ef;
    }

    td {
      height: 1.41rem;
    }
  }

  .right-content {
    .line2 {
      margin-top: 0.21rem;
    }
  }
}

.pagination {
  width: 30%;
  // float: right;
}

/deep/.van-pagination__item--active {
  background-color: #1588F5;
}

.nullStyle {
  padding-top: 2rem;
  text-align: center;
  padding-bottom: 2rem;

  img {
    margin: 0 auto;
    width: 2.53rem;
    height: 1.77rem;
  }
}

.tabletitle {
  border: 0.05rem solid #fff;
}

.total {
  height: 1.41rem;
  display: flex;
  width: 2.075rem;
  justify-content: center;
  align-items: center;
}
</style>